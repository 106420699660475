<style scoped>
/* /deep/ .el-autocomplete-suggestion {
  width: auto!important;
}
.el-select > .el-select-dropdown {
  transform:translateX(-100px);
} */
</style>

<template>
  <div class="vue-box">
    <!-- 参数栏 -->
    <div class="c-panel">
      <div class="c-title">主体信息</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px" :model="subject" ref="subject" :rules="rules">

          <el-form-item label="选择直付通服务商:" placeholder="请选择" prop="appId">
            <el-select v-model="subject.appId" @click.native="getF2f()">
              <el-option v-for="item in zftlist" :key="item.appid" :label="item.mchName" :value="item.appid" />
            </el-select>
            <el-button v-if="subject.appId" @click="showAuthorization">商家授权</el-button>
          </el-form-item>
          <el-form-item>
            <el-alert type="warning" description="请提前进行商家授权!!!" show-icon>
            </el-alert>
          </el-form-item>

          <el-form-item label="签约支付宝号:" prop="account">
            <el-input style="width: 350px" v-model="subject.account" placeholder="请填写签约支付宝号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-alert type="warning" description="注：签约支付宝号必须已实名认证" show-icon>
            </el-alert>
          </el-form-item>

          <el-form-item label="联系人姓名:" prop="contactName">
            <el-input style="width: 350px" v-model="subject.contactName" placeholder="请填写联系人姓名"></el-input>
          </el-form-item>

          <el-form-item label="联系人手机号:" prop="contactMobile">
            <el-input style="width: 350px" v-model="subject.contactMobile" placeholder="请填写联系人手机号"></el-input>
          </el-form-item>

          <el-form-item label="联系人邮箱[可选]:">
            <el-input style="width: 350px" v-model="subject.contactEmail" placeholder="请填写联系人邮箱"></el-input>
          </el-form-item>

          <el-form-item label="选择经营类目:" prop="mcc">
            <el-cascader v-model="subject.mcc" :options="options.data" @change="handleChange"></el-cascader>
          </el-form-item>
          <el-form-item>
            <el-alert type="info"
                      description="营业执照经营范围包含网络游戏制作、经营、销售、系统服务、技术、计算机、通讯设备、电子产品、互联网、网络技术、网络工程等任意一项业务"
                      show-icon>
            </el-alert>
          </el-form-item>

          <el-form-item label="营业执照">
            <el-upload ref="upload"
                       action=""
                       class="upload-demo" drag
                       :http-request="businessLicensePicHttpRequest"
                       :multiple="false"
                       :limit="1"
                       :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>

          <el-form-item label="服务费率[0.38~0.6之间]:" prop="rate">
            <el-input style="width: 350px" v-model="subject.rate" placeholder="请填写服务费率"></el-input>
          </el-form-item>

          <el-form-item label="签约且授权标识:">
            <el-radio-group v-model="subject.signAndAuth">
              <el-radio-button :label="true">签约且授权</el-radio-button>
              <el-radio-button :label="false">仅签约</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="营业执照号码:">
            <el-input style="width: 350px" v-model="subject.businessLicenseNo"
                      placeholder="请填写营业执照号码"></el-input>
          </el-form-item>
          <el-form-item label="是否长期有效[可选]:">
            <el-radio-group v-model="subject.longTerm">
              <el-radio-button :label="true">长期</el-radio-button>
              <el-radio-button :label="false">非长期</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="subject.longTerm === false" label="营业期限[可选]:">
            <el-input style="width: 350px" v-model="subject.dateLimitation" placeholder="请填写营业期限"></el-input>
          </el-form-item>
          <el-form-item label="店铺内景图片[可选]:">
            <el-upload ref="shopScenePicUpload"
                       action=""
                       class="upload-demo" drag
                       :http-request="shopScenePicHttpRequest"
                       :multiple="false"
                       :limit="1"
                       :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="店铺门头照图片[可选]:">
            <el-upload ref="shopSignBoardPicUpload"
                       action=""
                       class="upload-demo" drag
                       :http-request="shopSignBoardPicHttpRequest"
                       :multiple="false"
                       :limit="1"
                       :auto-upload="false">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="店铺名称[可选]:">
            <el-input style="width: 350px" v-model="subject.shopName" placeholder="请填写店铺名称"></el-input>
          </el-form-item>
          <el-form-item label="店铺地址[可选]:">
            <el-cascader
              size="large"
              :options="regionData"
              v-model="selectedOptions"
              @change="cascaderHandleChange">
            </el-cascader>
          </el-form-item>
          <el-form-item label="法人手机号码:">
            <el-input style="width: 350px" v-model="subject.businessLicenseMobile"
                      placeholder="请填写法人手机号码"></el-input>
          </el-form-item>
          <el-alert type="info"
                    description="被邀请授权的营业执照法人手机号码，上传非同人营业执照时必填"
                    show-icon>
          </el-alert>


        </el-form>
      </el-row>
    </div>

    <div class="c-panel">
      <div class="c-title">提交审核</div>
      <el-row type="flex" justify="left" align="middle">
        <el-form label-width="200px">

          <el-form-item>
            <span class="c-label">
              <el-button type="primary" @click="submit()">确认提交</el-button></span>
          </el-form-item>
        </el-form>
      </el-row>
    </div>

    <el-dialog title="商户授权"
               :modal="false"
               :visible.sync="authorizationVisible"
               width="30%">
      <div style="text-align: center">
        <span style="color: black;font-size: 18px">支付宝扫码授权</span><br>
        <el-image :src="appAuthorizationUrl"></el-image>
        <el-link :href="pcAuthorizationUrl" target="_blank">PC端点击授权:{{ pcAuthorizationUrl }}</el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mockData from './data-list.js'
import {
  regionData,
} from 'element-china-area-data'

export default {
  data () {
    var checkRate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('服务费率不能为空'))
      }
      setTimeout(() => {
        if (value < 0.38 || value > 0.6) {
          callback(new Error('服务费率在[0.38~0.6]之间'))
        } else {
          callback()
        }
      }, 1000)
    }
    return {
      options: mockData,
      selectedOptions: [],
      regionData: regionData,
      subject: {
        appId: '',
        account: '',
        contactName: '',
        contactMobile: '',
        contactEmail: '',
        specialLicensePic: '',
        rate: '0.6',
        mcc: '',
        mccCode: '',
        signAndAuth: false,
        businessLicenseNo: '',
        businessLicensePic: '',
        businessLicenseAuthPic: '',
        longTerm: true,
        dateLimitation: '',
        shopScenePic: '',
        shopSignBoardPic: '',
        shopName: '',
        countryCode: 156,
        provinceCode: '',
        cityCode: '',
        districtCode: '',
        detailAddress: '',
        businessLicenseMobile: '',
      },
      authorizationVisible: false,
      pcAuthorizationUrl: '',
      appAuthorizationUrl: '',
      zftlist: [],
      rules: {
        appId: [
          { required: true, message: '服务商必须选择', trigger: 'change' },
        ],
        account: [
          { required: true, message: '签约支付宝号必须添加', trigger: 'blur' },
          { min: 1, max: 128, message: '签约支付宝号长度在1-128之间', trigger: 'blur' },
        ],
        contactName: [
          { required: true, message: '联系人名称不能为空', trigger: 'blur' },
          { min: 1, max: 32, message: '联系人名称长度在1-32之间', trigger: 'blur' },
        ],
        contactMobile: [
          { required: true, message: '联系人手机号码不能为空', trigger: 'blur' },
          { max: 16, message: '联系人手机号码长度不大于16', trigger: 'blur' },
        ],
        contactEmail: [
          { required: true, message: '联系人邮箱不能为空', trigger: 'blur' },
          { max: 64, message: '联系人邮箱长度不大于64', trigger: 'blur' },
        ],
        mcc: [
          { required: true, message: '商家经营类目编码不能为空', trigger: 'change' },
        ],
        rate: [
          { validator: checkRate, trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    ok: function () {
    },
    handleChange (value) {
      console.log(value)
      this.subject.mccCode = value[0] + '_' + value[1]
    },
    showAuthorization () {

      this.pcAuthorizationUrl = 'https://b.alipay.com/page/message/tasksDetail' +
        '?bizData=' + encodeURIComponent('{"platformCode":"O","taskType":"INTERFACE_AUTH"' +
          ',"agentOpParam":{"redirectUri":"' + this.sa.cfg.api_url + '/v1/index/authRedirect"' +
          ',"appTypes":["TINYAPP","WEBAPP","PUBLICAPP","MOBILEAPP"]' +
          ',"isvAppId":"' + this.subject.appId + '"}}')
      this.appAuthorizationUrl = this.sa.cfg.api_url +
        '/v1/index/enQrcode?url=' +
        encodeURIComponent('alipays://platformapi/startapp?appId=2021003130652097&page=' + encodeURIComponent('pages/authorize/index?bizData='
          + encodeURIComponent('{"platformCode":"O","taskType":"INTERFACE_AUTH"' +
            ',"agentOpParam":{"redirectUri":"' + this.sa.cfg.api_url + '/v1/index/authRedirect"' +
            ',"appTypes":["TINYAPP","WEBAPP","PUBLICAPP","MOBILEAPP"]' +
            ',"isvAppId":"' + this.subject.appId + '"}}')))

      this.authorizationVisible = true
    },
    businessLicensePicHttpRequest (param) {
      this.subject.businessLicensePic = param.file
    },
    shopScenePicHttpRequest (param) {
      this.subject.shopScenePic = param.file
    },
    shopSignBoardPicHttpRequest (param) {
      this.subject.shopSignBoardPic = param.file
    },
    submit () {
      this.$refs.upload.submit()
      this.$refs.shopScenePicUpload.submit()
      this.$refs.shopSignBoardPicUpload.submit()
      this.$refs.subject.validate((valid) => {
        if (valid) {
          console.log(this.subject)
          // 新建form表单
          const fd = new FormData()
          // 将data转换为form-data
          Object.keys(this.subject).forEach(key => {
            if (this.subject[key] !== null && this.subject[key] !== undefined && this.subject[key] !== '') {
              if (this.subject[key] instanceof Array) {
                // 如果是数组就循环加入表单，key保持相同即可，这就是表达单的数组
                this.subject[key].forEach(item => {
                  fd.append(key, item)
                })
              } else {
                // 如果不是数组就直接追加进去
                fd.append(key, this.subject[key])
              }
            }
          })

          var defaultCfg = {
            type: 'post',
            fromDataFlag: true,
          }
          this.sa.ajax(
            '/v1/alipay/f2f/merchant/create', fd,
            function (res) {
              console.log(res)
              this.sa.ok('提交成功')
            }.bind(this),
            defaultCfg,
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })

    },
    getF2f: function () {
      var defaultCfg = {
        sleep: 50,
        isBody: true, //是否是请求体请求
        msg: null,
      }
      var p = {
        page: 0,
        type: [4],
        payType: [0],
        limit: 30,
      }
      this.sa.ajax(
        '/v1/alipay/config/query', p,
        function (res) {
          this.zftlist = res.data.records // 数据
        }.bind(this),
        defaultCfg,
      )
    },
    cascaderHandleChange (param) {
      this.subject.provinceCode = param[0].toString().padEnd(6, '0')
      this.subject.cityCode = param[1].toString().padEnd(6, '0')
      this.subject.districtCode = param[2].toString().padEnd(6, '0')
    },
  },
  created () {
    // this.getZft();
  },
  directives: {
    'el-select-loadmore': {
      bind (el, binding) {
        const SELECTWRAP_DOM = el.querySelector(
          '.el-select-dropdown .el-select-dropdown__wrap',
        )
        SELECTWRAP_DOM.addEventListener('scroll', function () {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      },
    },
  },
}
</script>


