module.exports = {
  data: [
    {
      value: 'A0001',
      label: '餐饮',
      children: [
        {
          value: 'B0001',
          label: '中式正餐',
        },
        {
          value: 'B0002',
          label: '西式正餐',
        },
        {
          value: 'B0003',
          label: '日韩/东南亚菜',
        },
        {
          value: 'B0004',
          label: '中式快餐',
        },
        {
          value: 'B0005',
          label: '西式快餐',
        },
        {
          value: 'B0006',
          label: '小吃/熟食',
        },
        {
          value: 'B0009',
          label: '饮品/甜品',
        },
        {
          value: 'B0010',
          label: '烘焙糕点',
        },
        {
          value: 'B0011',
          label: '酒吧/清吧',
        },
        {
          value: 'B0012',
          label: '咖啡馆/茶馆',
        },
        {
          value: 'B0117',
          label: '订餐服务平台',
        },
        {
          value: 'B0199',
          label: '火锅',
        },
        {
          value: 'B0200',
          label: '宴会提供商',
        },
      ],
    },
    {
      value: 'A0002',
      label: '零售批发',
      children: [
        {
          value: 'B0013',
          label: '杂货店',
        },
        {
          value: 'B0014',
          label: '连锁便利店',
        },
        {
          value: 'B0015',
          label: '超市',
        },
        {
          value: 'B0016',
          label: '大卖场',
        },
        {
          value: 'B0017',
          label: '百货商场',
        },
        {
          value: 'B0018',
          label: '购物中心',
        },
        {
          value: 'B0020',
          label: '商业街',
        },
        {
          value: 'B0021',
          label: '水果店',
        },
        {
          value: 'B0022',
          label: '茶叶',
        },
        {
          value: 'B0025',
          label: '营养品/保健品',
        },
        {
          value: 'B0026',
          label: '服饰鞋包',
        },
        {
          value: 'B0028',
          label: '美妆个护',
        },
        {
          value: 'B0006',
          label: '黄金珠宝/钟表饰品',
        },
        {
          value: 'B0043',
          label: '其他专业零售店',
        },
        {
          value: 'B0114',
          label: '互联网综合电商平台',
        },
        {
          value: 'B0115',
          label: '互联网垂直电商平台',
        },
        {
          value: 'B0203',
          label: '办公用品',
        },
        {
          value: 'B0216',
          label: '家居家纺',
        },
      ],
    },
    {
      value: 'A0007',
      label: '网络通讯',
      children: [
        {
          value: 'B0113',
          label: '信息检索服务/网络论坛',
        },
        {
          value: 'B0120',
          label: '互联网数据服务',
        },
        {
          value: 'B0121',
          label: '软件开发服务',
        },
        {
          value: 'B0121',
          label: '网络推广/网络广告',
        },
      ],
    },
  ],
}
